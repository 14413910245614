import React, { useState, useEffect } from 'react';
import { Button, FluentProvider, webLightTheme, webDarkTheme } from '@fluentui/react-components';


const JsonViewer = () => {
  const [jsonInput, setJsonInput] = useState('');
  const [isDarkTheme, setIsDarkTheme] = useState(true); // Start with dark mode
  const [message, setMessage] = useState('');
  const [fontSize, setFontSize] = useState(16); // Initial font size

  useEffect(() => {
    if (message !== '') {
      const timer = setTimeout(() => setMessage(''), 1000); // Hide message after 3 seconds
      return () => clearTimeout(timer);
    }
  }, [message]);

  const formatJson = () => {
    try {
      const formatted = JSON.stringify(JSON.parse(jsonInput), null, 2);
      setJsonInput(formatted);
      setMessage('JSON formatted successfully.');
    } catch (error) {
      setMessage('Invalid JSON');
    }
  };

  const clearInput = () => {
    setJsonInput('');
    setMessage('Input cleared');
  };

  const toggleTheme = () => {
    setIsDarkTheme(!isDarkTheme);
  };

  const copyToClipboard = async (text) => {
    try {
      await navigator.clipboard.writeText(text);
      setMessage('Copied to clipboard.');
    } catch (error) {
      setMessage('Failed to copy.');
    }
  };

  const copyFormattedJson = () => {
    try {
      const formatted = JSON.stringify(JSON.parse(jsonInput), null, 2);
      copyToClipboard(formatted);
    } catch (error) {
      setMessage('Invalid JSON');
    }
  };

  const copyCompactJson = () => {
    try {
      const compact = JSON.stringify(JSON.parse(jsonInput));
      copyToClipboard(compact);
    } catch (error) {
      setMessage('Invalid JSON');
    }
  };

  const increaseFontSize = () => setFontSize(fontSize + 1);
  const decreaseFontSize = () => setFontSize(fontSize > 10 ? fontSize - 1 : fontSize);

  const theme = isDarkTheme ? webDarkTheme : webLightTheme;
  const containerStyle = {
    height: '100vh',
    display: 'flex',
    flexDirection: 'row', // Changed to row for sidebar layout
    transition: 'all 0.6s ease',
    backgroundColor: isDarkTheme ? '#2b2b2b' : '#fff',
    color: isDarkTheme ? '#ffffff' : '#000000',

  };
  const compactJson = () => {
  try {
    const compact = JSON.stringify(JSON.parse(jsonInput));
    setJsonInput(compact);
    setMessage('JSON compacted successfully.');
  } catch (error) {
    setMessage('Invalid JSON');
  }
};
  const copyForPython = () => {
  try {
    const formatted = JSON.stringify(JSON.parse(jsonInput), null, 2)
      .replace(/true/g, 'True')
      .replace(/false/g, 'False')
      .replace(/null/g, 'None');
    copyToClipboard(formatted);
    setMessage('Copied for Python.');
  } catch (error) {
    setMessage('Invalid JSON');
  }
};

  return (
    <FluentProvider theme={theme}>
      <div style={containerStyle}>
        <div style={{ flexGrow: 1, display: 'flex', flexDirection: 'column' }}>
          <div style={{ padding: '10px', display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
            <div style={{ display: 'flex', gap: '10px' }}>
              <Button onClick={toggleTheme}>{isDarkTheme ? 'Light Mode' : 'Dark Mode'}</Button>
              <Button onClick={formatJson}>Format JSON</Button>
              <Button onClick={copyFormattedJson}>Copy Formatted</Button>
              <Button onClick={copyCompactJson}>Copy Compact</Button>
              <Button onClick={clearInput}>Clear</Button>
              <Button onClick={copyForPython}>Copy for Python</Button>
              <Button onClick={compactJson}>Compact JSON</Button>
            </div>
            <div style={{ flexGrow: 1, textAlign: 'right' }}>{message}</div> {/* Adjusted for message alignment */}
          </div>
          <textarea
            value={jsonInput}
            onChange={(e) => setJsonInput(e.target.value)}
            style={{
              flexGrow: 1,
              border: 'none',
              padding: '10px',
              resize: 'none',
              transition: 'all 0.6s ease',
              fontSize: `${fontSize}px`,
              backgroundColor: isDarkTheme ? '#2b2b2b' : '#fff',
              color: isDarkTheme ? '#ffffff' : '#000000',
            }}
            placeholder="Paste your JSON here"
          />
        </div>
        <div style={{ display: 'flex', flexDirection: 'column', padding: '10px', justifyContent: 'center', borderLeft: '1px solid #ccc', height: '100%', width: '60px' }}>
          <Button onClick={increaseFontSize} style={{ marginBottom: '10px', padding: '0', minWidth: '40px', justifyContent: 'center' }}>A+</Button>
          <Button onClick={decreaseFontSize} style={{ padding: '0', minWidth: '40px', justifyContent: 'center' }}>A-</Button>
        </div>
      </div>
    </FluentProvider>
  );
};

export default JsonViewer;