// src/App.js
import React from 'react';
import JsonViewer from './JsonViewer';
import { FluentProvider, webLightTheme } from '@fluentui/react-components';

function App() {
  return (
    <FluentProvider theme={webLightTheme}>
      <div className="App">
        <header className="App-header">
          <JsonViewer />
        </header>
      </div>
    </FluentProvider>
  );
}

export default App;